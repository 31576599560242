define('modules/asyncTwitter',[], function () {   
    
    return {
        init: function() {
            var twitterContainer = $('.async-twitter'), self = this;
            if (twitterContainer.length > 0) {
                twitterContainer.each(function() {
                    var container = $(this);
                    if (typeof container.data('source') != 'undefined') {
                        self.getTweets(container);
                    }
                });
                
            }

        },
        getTweets: function(container) {

            var keyData = container.data('source'), 
                keyTS = keyData + '_ts', 
                ttl = 3600000, //1 hour yttl in ms
                url = '/twitter?source=' + keyData;
            
            if (!this.supportsLocalStorage() || typeof localStorage.getItem(keyData) == 'undefined' || (new Date().getTime() - localStorage.getItem(keyTS)) > ttl ) {
                //console.log('cache miss');
                self = this;

                $.getJSON(url, function(data) {
                    if (self.supportsLocalStorage()) {
                        localStorage.setItem(keyData, JSON.stringify(data));
                        localStorage.setItem(keyTS, new Date().getTime());
                        //console.log('adding to cache');
                    }
                    self.populateTweets(data, container);
                }).fail(function() {
                    container.hide();
                });;
            } else {
                this.populateTweets($.parseJSON(localStorage.getItem(keyData)), container);
            }
        },
        populateTweets: function(data, container) {
            //console.log(data);
            var i = 0, self = this;
            container.find('.bubble-container').each(function() {
                bubbleContainer = $(this);
                if (typeof data[i] != 'undefined') {
                    bubbleContainer.find('.async-twitter-text').html(data[i].text);
                    bubbleContainer.find('.async-twitter-timeago').html(self.readableTimeFromPresent(data[i].timestamp));
                    bubbleContainer.find('.async-twitter-screenname').html('@' + data[i].screenName);
                    bubbleContainer.find('.async-twitter-name').html(data[i].name);
                    bubbleContainer.find('.async-twitter-userurl').attr('href', data[i].userUrl);
                    bubbleContainer.find('.async-twitter-favouritecount').html(data[i].favouriteCount);
                    bubbleContainer.find('.async-twitter-retweetcount').html(data[i].retweetCount);
                    bubbleContainer.find('.async-twitter-replyurl').attr('href', data[i].replyUrl);
                    bubbleContainer.find('.async-twitter-favouriteurl').attr('href', data[i].favouriteUrl);
                    bubbleContainer.find('.async-twitter-retweeturl').attr('href', data[i].retweetUrl);
                    bubbleContainer.find('.async-twitter-followurl').attr('href', data[i].followUrl);
                    bubbleContainer.find('.async-twitter-profileimgurl').attr('src', data[i].profileImageUrl);
                    
                    // only show primary media if we have one
                    if (data[i].primaryMediaUrl != null) {
                        bubbleContainer.find('.async-twitter-primarymediarl').attr('src', data[i].primaryMediaUrl);
                    } else {
                        bubbleContainer.find('.async-twitter-primarymediarl').hide();
                    }
                    
                    // dates need to be formated as required
                    var dateElement = bubbleContainer.find('.async-twitter-primarymediarl'), format = 'dd-m-yy', date = new Date(data[i].timestamp * 1000);
                    if (dateElement.length) {
                        if (typeof dateElement.data('format') != 'undefined') {
                            format = dateElement.data('format');
                        }
                        dateElement.html(date.format(format));
                    }
                    
                    
                    i++;
                } else {
                    bubbleContainer.remove();
                }

            });
        },
        supportsLocalStorage: function() {
            try {
                return 'localStorage' in window && window['localStorage'] !== null;
            } catch (e) {
                return false;
            }
        },
        readableTimeFromPresent: function(timestamp) {

            var timeNow = new Date().getTime();

            var currentTimestamp =  Math.round(timeNow / 1000),
                delta = currentTimestamp - timestamp,
                timeUnits = {
                    '60': 'Minute',
                    '3600': 'Hour',
                    '86400': 'Day',
                    '2592000': 'Month',
                    '31104000': 'Year'
                },
                quantity = 0.
                time = '';


            if(timestamp == 0){
                return '';
            }

            if (delta < 60) {
                return 'Just now';
            }
            
            for (var seconds in timeUnits) {

                quantity = delta / parseInt(seconds);
                if (quantity >= 1) {
                    quantity = Math.round(quantity);
                    time = quantity + ' ' + timeUnits[seconds] + (quantity > 1 ? 's' : '');
                }
            }
            
            return time + ' ago';
            
        }
    };
});
