require.config({
    paths: {
        jquery: '../bower_components/jquery/dist/jquery.min',
        bootstrap: '../vendor/bootstrap/3.0.17/js/sass-bootstrap.min',
        owl: '../vendor/owl.carousel/1.31/owl.carousel.min',
        PageView: 'PageView/view',
        carouFredSel: '../vendor/caroufredsel/jquery.carouFredSel-6.2.1-packed',
        cookieCutter: '../vendor/cookie-cutter/cookie-cutter',
        froogaloop: '../vendor/froogaloop/froogaloop',
        slidemenu: 'modules/slidemenu'
    },
    shim: {
        bootstrap: {
            deps: ["jquery"]
        },
        owl: {
            deps: ['jquery']
        },
        carouFredSel: {
            deps: ['jquery']
        },
        cookieCutter: {
            deps: ['jquery']
        },
        froogaloop: {
            deps: ['jquery'],
            exports: '$f'
        },
        slidemenu: {
            deps: ['jquery']
        }
    }
});

require(['jquery', 'bootstrap', 'slidemenu', 'owl', 'modules/linkifyObfuscatedEmails', 'modules/asyncTwitter'], function ($, Bootstrap, slideMenu, owlCarousel, linkifyObfuscatedEmails, asyncTwitter) {

    $("#slide-nav").slideMenu();

    asyncTwitter.init();

    //obfuscateEmails.init();

    // TODO - do we have a modular system for these kind of helper functions?
    function linkifyObfuscatedEmails() {
        $('.obfuscate-email').each(function () {
            var email = $(this);
            $('span', email).remove();
            email.html('<a href="mailto:' + email.html() + '">' + email.html() + '</a>');
        });
    }
    linkifyObfuscatedEmails();

    var blockCarousel = $('.block-carousel');
    if (blockCarousel.length) {
        var element = $('.owl-carousel');
        element.owlCarousel({
            autoPlay: 3000,
            items: 1,
            itemsDesktop: [1199, 1],
            itemsDesktopSmall: [979, 1],
            navigation: false,
            stopOnHover: true
        });

        // Custom Navigation Events
        blockCarousel.find(".next").click(function () {
            element.trigger('owl.next');
        });
        blockCarousel.find(".prev").click(function () {
            element.trigger('owl.prev');
        });
    }

    //news load more
    if ($('.load-more-news a').length > 0) {
        var pageNumber = 1, maxNumber = $('.load-more-news a').data('count');
        $('.load-more-news a').click(function () {
            var url = window.location.href + '/page/' + (++pageNumber);
            $.get(url, function (html) {
                html = $(html);
                html.find('div.news-listing').each(function () {
                    var newItem = $(this);
                    if ($("div[data-id=" + newItem.data('id') + "]").length == 0) {
                        $('div.news-listing').last().after(newItem);
                        added = true;
                    }
                });
                if (pageNumber >= maxNumber) {
                    $('.load-more-news a').addClass('disabled');
                }
            });
            return false;
        });
    }



    $('#experts-carousel').owlCarousel({
        items: 6,
        itemsDesktop: [1199, 5],
        itemsDesktopSmall: [980, 4],
        itemsTablet: [768, 2],
        navigation: true,
        navigationText: ["", ""],
        pagination: false
    });

    $('#reports-carousel').owlCarousel({
        items: 4,
        itemsDesktop: [1199, 4],
        itemsDesktopSmall: [980, 3],
        itemsTablet: [768, 2],
        navigation: true,
        navigationText: ["", ""],
        pagination: false
    });

    $('#events-days-carousel').owlCarousel({
        items: 3,
        itemsDesktop: [1199, 3],
        itemsDesktopSmall: [980, 2],
        itemsTablet: [768, 2],
        navigation: true,
        navigationText: ["", ""],
        pagination: false
    });

    var timeoutID = window.setTimeout(function () {
        $('#homepage-banner-carousel').owlCarousel({
            items: 1,
            itemsDesktop: [1199, 1],
            itemsDesktopSmall: [980, 1],
            itemsTablet: [768, 1],
            itemsMobile: [479, 1],
            singleItem: true,
            navigation: true,
            navigationText: ["", ""],
            pagination: false,
            autoPlay: true
        });
    }, 750);

    // Main navigation search bar toggle
    $(".toggle-search").click(function () {
        $(".toggle-search").parents('.search-bar-wrapper').toggleClass('open');
    });

    $(document).ready(function() {
      $('.footer .element-email').after('<p><a style="text-align:right" class="phone" href="/privacy-policy" target="_blank"><i class="fa fa-external-link" aria-hidden="true"></i> Our Privacy policy</a></p>');
    });

});

define("main", function(){});

