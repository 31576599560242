define('modules/linkifyObfuscatedEmails',[], function () {   
    
    return {
       init: function () {
           
            $('.obfuscate-email').each(function() {
                var email = $(this);
                $('span', email).remove();
                email.html('<a href="mailto:' + email.html() + '">' + email.html() + '</a>');
            });
            
       }
    };
});
