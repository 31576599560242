define('slidemenu',['jquery'], function ($) {

// Create the defaults once
    var pluginName = "slideMenu",
        defaults = {
            //  You must set these
            slideMenuSelector:  '#slidemenu',
            slideMenuContainer: '#slide-nav',
            slideActiveListeners: '#wrapper, .header-bar, body, .navbar-header, html',
            activeWhen: function(self) {    //  when the slide menu should be active
                return !($(window).width() > 767 && $(self.options.toggler).is(':hidden'));
            },
            
            //  These should not need to be overridden
            slideModalId:       'navbar-height-col',
            slideActiveClass:   'slide-active',
            toggler:           '.navbar-toggle',
            pagewrapper:        '#wrapper',
            pageheaderwrapper:  '.header-bar',
            navigationwrapper:  '.navbar-header',
            menuwidth:          '100%', // the menu inside the slide menu itself
            slidewidth:         '80%',
            menuneg:            '-100%',
            slideneg:           '-80%'
        };

    // The actual plugin constructor
    function Plugin( element, options ) {
        this.element = element;

        this.options = $.extend( {}, defaults, options) ;

        this._defaults = defaults;
        this._name = pluginName;

        this.init();
    }

    Plugin.prototype = {

        init: function() {
            
            var self = this;
            var slideMenuContainer = this.element;
            
            with (this.options) {
                //slide nav
                //stick in the fixed 100% height behind the navbar but don't wrap it
                $(slideMenuContainer).find('.container').append('<div id="' + slideModalId + '"></div>');

                $(slideMenuContainer).find('.container').on("click", '#' + slideModalId, function (e) {
                    if ($(slideMenuContainer).hasClass(slideActiveClass)) {
                        self.toggle();
                    };
                });

                //  Click event on the menu button
                $(slideMenuContainer).on("click", toggler, function() {
                    self.toggle();
                });

                //  Disable the slide menu if 
                $(window).on("resize", function () {
                    if (self.options.activeWhen(self)) {
                        $(slideActiveListeners).add(slideMenuContainer).removeClass(slideActiveClass);
                    }
                });
            }
        },
        
        //  Performs the toggling action
        toggle: function() {
            with (this.options) {
                var selected = $(this.element).hasClass(slideActiveClass);

                $(slideMenuSelector).stop().animate({
                    right: selected ? menuneg : '0px'
                });

                $('#' + slideModalId).stop().animate({
                    right: selected ? slideneg : '0px'
                });

                $(pagewrapper).stop().animate({
                    right: selected ? '0px' : slidewidth
                });

                $(pageheaderwrapper).stop().animate({
                    right: selected ? '0px' : slidewidth
                });

                $(navigationwrapper).stop().animate({
                    right: selected ? '0px' : slidewidth
                });

                $(slideMenuSelector).toggleClass(slideActiveClass);

                $(slideActiveListeners).add(this.element).toggleClass(slideActiveClass);
            }
        }
    };

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[pluginName] = function ( options ) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName,
                new Plugin( this, options ));
            }
        });
    };
    
});
